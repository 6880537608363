// ========= Career Starts =============
.headline{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 30px;
    font-weight: bold;
}
.table{
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
.button{
    color: white;
    padding: 2%;
    background-color:brown
}
.left-contents{
    padding-left: 5%;
    padding-top: 5%;
}
.right-contents{
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 5%;

}
.card{
    display: flex;
    justify-content: center;
    align-items: center;
}
.apply{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 50%;
    font-weight: bold;
    background-color: $default-blue;
    border-radius: 4px;
    .link{
        color: white;
    }
}
.right-description{
    padding-top: 5%;
}