/* 12- blog - start
==================================================================================================== */

/* 12.1 - blog page - start
================================================== */
.blog-section {
  .section-title{
    margin-top: -100px;
  }
  .blog-carousel {
    margin-bottom: -40px;
    .swiper-pagination {
      bottom: -55px;
    }
    position: relative;
    &:after,
    &:before {
      content: "";
      z-index: -1;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &:before {
      left: -105px;
      bottom: 40px;
      width: 210px;
      height: 220px;
      background-image: url("../../../public/assets/images/blog/shape-1.png");
    }
    &:after {
      top: -30px;
      right: -65px;
      width: 230px;
      height: 210px;
      background-image: url("../../../public/assets/images/blog/shape-2.png");
    }
    .swiper-container {
      position: static;
    }
  }

  /* blog-grid-item - start */
  .big-blog-item,
  .blog-grid-item {
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 50px;
    border: 1px solid $deep-gray;
    background-color: $pure-white;
    .blog-image {
      overflow: hidden;
      position: relative;
      > img {
        width: 100%;
        display: block;
        transform: scale(1);
        transition: $default-transition;
      }
      .post-date {
        left: 25px;
        bottom: 25px;
        padding: 10px 15px;
        text-align: center;
        border-radius: 3px;
        position: absolute;
        color: $default-black;
        border: 1px solid $deep-gray;
        background-color: rgba(255, 255, 255, 0.85);
        .date-text {
          width: 100%;
          display: table;
          line-height: 0.8;
          font-size: 20px;
          margin-bottom: 8px;
        }
        .month-text {
          width: 100%;
          display: table;
          line-height: 0.8;
        }
      }
    }
    .blog-content {
      width: 100%;
      display: table;
      padding: 30px 25px;
      .tag-link {
        width: auto;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
        padding: 0px 15px;
        margin-bottom: 20px;
        color: $default-black;
        text-transform: uppercase;
        border: 1px solid $deep-gray;
        background-color: $light-gray;
      }
      .blog-title {
        .title-link {
          font-size: 20px;
          line-height: 30px;
          color: $default-black;
          font-weight: $fw-bold;
        }
      }
    }
    &:hover {
      .blog-image {
        > img {
          transform: scale(1.1);
        }
      }
      .blog-content {
        .blog-title {
          .title-link {
            color: $default-blue;
          }
        }
      }
    }
  }
  /* blog-grid-item - end */

  /* big-blog-item - start */
  .big-blog-item {
    .blog-image {
      .post-date {
        left: 50px;
        bottom: 50px;
      }
    }
    .blog-content {
      padding: 50px;
      .post-tags {
        > ul {
          > li {
            margin-right: 20px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
      .blog-title {
        .title-link {
          font-size: 30px;
          line-height: 36px;
        }
      }
      .item-paragraph {
        font-size: 16px;
      }
    }
  }
  /* big-blog-item - end */
}
/* 12.1 - blog page - start
================================================== */

/* 12.2 - blog details page - start
================================================== */
.blog-details-page-1 {
  .breadcrumb-section {
    padding: 0px;
    .overlay-black {
      width: 100%;
      height: 650px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.blog-details-page-1 {
  .blog-details-section {
    position: relative;
    .blog-details-content {
      z-index: 1;
      padding: 50px;
      position: relative;
      border-radius: 3px;
      margin-top: -230px;
      background-color: $pure-white;
    }
  }
}

.blog-details-section {
  .blog-details-content {
    > p {
      font-size: 16px;
      > a {
        color: $default-blue;
      }
    }
    .blog-title {
      font-size: 36px;
      line-height: 48px;
    }
    .title-text {
      font-size: 21px;
    }

    .post-meta {
      > ul {
        > li {
          > .post-admin {
            padding: 3px 0px;
            font-weight: $fw-bold;
            color: $default-black;
            .admin-image {
              float: left;
              width: 30px;
              height: 30px;
              overflow: hidden;
              margin: -6px 0px;
              margin-right: 10px;
              border-radius: 100%;
              border: 1px solid $deep-gray;
            }
          }
        }
      }
    }

    .blockquote {
      margin: 50px 30px;
      padding: 30px 40px;
      border-left: 6px solid $default-blue;
      > p {
        font-size: 18px;
        line-height: 30px;
        color: $default-black;
      }
    }

    .video-item {
      width: 620px;
      overflow: hidden;
      margin: 50px auto;
      position: relative;
      .overlay {
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .popup-video {
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 65px;
        height: 65px;
        font-size: 24px;
        line-height: 65px;
        position: absolute;
        text-align: center;
        border-radius: 100%;
        color: $default-pink;
        background-color: $pure-white;
        transform: translate(-50%, -50%);
        animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
        &:before {
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: -1;
          content: "";
          position: absolute;
          border-radius: 100%;
          box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
        }
      }
    }

    .features-list {
      > ul {
        width: 550px;
        > li {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0px;
          }
          .icon {
            float: left;
            font-size: 18px;
            margin-right: 30px;
            color: $default-blue;
          }
          .info-text {
            display: table;
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }

    .post-admin {
      padding: 30px 60px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      .admin-image {
        float: left;
        width: 70px;
        height: 70px;
        overflow: hidden;
        margin-right: 20px;
        text-align: center;
        border-radius: 100%;
        border: 2px solid $deep-gray;
      }
      .admin-content {
        display: table;
        > p {
          color: #2b2b3e;
          font-size: 18px;
          line-height: 30px;
        }
        .admin-name {
          width: 100%;
          display: table;
          font-size: 16px;
          position: relative;
          padding-left: 25px;
          color: $default-black;
          font-weight: $fw-medium;
          &:before {
            top: 50%;
            left: 0px;
            width: 15px;
            height: 2px;
            content: "";
            position: absolute;
            transform: translateY(-50%);
            background-color: $default-black;
          }
        }
      }
    }

    .tag-share-links {
      .title-text {
        float: left;
        font-size: 16px;
        padding: 5px 0px;
        margin-right: 20px;
        color: $default-black;
        font-weight: $fw-medium;
        text-transform: capitalize;
      }
      .tag-links {
        > ul {
          > li {
            margin-right: 15px;
            &:last-child {
              margin-right: 0px;
            }
            > a {
              width: auto;
              height: 30px;
              font-size: 12px;
              line-height: 30px;
              padding: 0px 15px;
              color: $default-blue;
              text-transform: uppercase;
              border: 1px solid $deep-gray;
              background-color: $light-gray;
            }
          }
        }
      }
      .share-links {
        > ul {
          > li {
            margin-right: 5px;
            &:last-child {
              margin-right: 0px;
            }
            > a {
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 30px;
              text-align: center;
              color: $default-color;
              &:hover {
                color: $default-blue;
              }
            }
          }
        }
      }
    }

    .peoples-comments {
      .title-text {
        font-size: 24px;
        font-weight: $fw-bold;
      }

      .comment-item {
        margin-bottom: 30px;
        padding-bottom: 40px;
        border-bottom: 1px solid $deep-gray;
        &:last-child {
          margin-bottom: 0px;
          border-bottom: none;
          padding-bottom: 0px;
        }
        .hero-image {
          float: left;
          width: 70px;
          height: 70px;
          overflow: hidden;
          margin-right: 30px;
          border-radius: 100%;
          border: 2px solid $deep-gray;
        }
        .comment-content {
          display: table;
          .hero-info {
            position: relative;
            margin-bottom: 20px;
            .hero-name {
              font-size: 18px;
              font-weight: $fw-bold;
            }
            .reply-btn {
              top: 50%;
              right: 0px;
              position: absolute;
              color: $default-color;
              font-weight: $fw-medium;
              text-transform: uppercase;
              transform: translateY(-50%);
              &:hover {
                color: $default-blue;
              }
            }
          }
          .paragraph-text {
            font-size: 16px;
          }
        }
        .comment-item {
          margin-top: 30px;
          padding-top: 40px;
          padding-left: 55px;
          border-top: 1px solid $deep-gray;
        }
      }
    }

    .comment-form {
      .title-text {
        font-size: 24px;
        font-weight: $fw-bold;
      }
    }
  }
}
/* 12.2 - blog details page - end
================================================== */

/* 12- blog - end
==================================================================================================== */
