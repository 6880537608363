.apply-form{
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
}
.file-button{
    color: #ffffff;
    padding-right: 5%;
    padding-left: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: $default-pink;
}
.upload-file{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
}
.upload-file-name{
    font-size: 1.3rem;
}
.submit-button{
    color: #ffffff;
    background-color: $default-pink;
    padding: 2% 3% 2% 3%;
}
.apply-message{
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
     p{
        display: flex;
        justify-content: center;
        font-size: 20px;
        color: white;
        background-color: $default-pink;
        padding-top: 10px;
        padding-bottom: 10px;
     }
}
.home-button{
    display: flex;
    justify-content: center;
}