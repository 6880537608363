/* 16 - contact pages - start
==================================================================================================== */
.contact-section {
  .contact-info-item {
    padding: 50px 30px;
    border-radius: 2px;
    border: 1px solid $deep-gray;
    .item-icon {
      width: 105px;
      height: 105px;
      display: table;
      font-size: 36px;
      color: $pure-white;
      position: relative;
      text-align: center;
      margin: 0 auto 30px;
      background-repeat: no-repeat;
      background-position: center center;
      > i {
        top: 65%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -65%);
      }
    }
    > ul {
      > li {
        color: #687596;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: $fw-medium;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .contact-info-item.phone-item {
    border-color: #7a49ff;
  }
  .contact-info-item.address-item {
    border-color: #31c8e9;
  }
  .contact-info-item.email-item {
    border-color: #ff718e;
  }
}

/* map-section - start */
.map-section {
  padding-top: 5%;
  position: relative;
  .feature-item {
    margin-bottom: 160px;
    &:last-child {
      margin-bottom: 0px;
    }
    .feature-image-1 {
      z-index: 1;
      padding-top: 45px;
      position: relative;
      padding-bottom: 25px;
      .circle-image {
        z-index: -1;
        width: 565px;
        margin-left: -100px;
        animation: spin 20s infinite linear;
      }
      .phone-image {
        top: -38px;
        left: -32px;
        z-index: 1;
        width: 438px;
        position: absolute;
      }
      .comment-image-1,
      .comment-image-2 {
        z-index: 2;
        width: 400px;
        position: absolute;
      }
      .comment-image-1 {
        left: 27px;
        top: 135px;
      }
      .comment-image-2 {
        left: 65px;
        bottom: 10px;
      }
    }

    .feature-content {
      font-size: 16px;
      padding-top: 80px;
      .section-title {
        .sub-title {
          padding: 5px;
          padding-left: 20px;
          border-left: 3px solid $default-pink;
        }
        .title-text {
          font-size: 36px;
          font-weight: $fw-medium;
        }
      }
      > p {
        font-size: 16px;
        text-align: justify;
        text-justify: inter-word;
      }
      .btns-group {
        > ul {
          > li {
            margin-right: 40px;
            &:last-child {
              margin-right: 0px;
            }
            .custom-btn-underline {
              margin: 18px 0px;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      .feature-image-1 {
        padding-top: 57px;
        .circle-image {
          margin-left: 0px;
          margin-right: -80px;
        }
        .phone-image {
          top: -29px;
          left: unset;
          right: -30px;
        }
        .comment-image-1,
        .comment-image-2 {
          width: 420px;
        }
        .comment-image-1 {
          top: 30px;
          left: 0px;
        }
        .comment-image-2 {
          left: unset;
          bottom: 35px;
          right: -136px;
        }
      }
    }
  }
  .google-map {
    width: 100%;
    border: 0;
    height: 930px;
    position: relative;
  }

  .contact-form {
    z-index: 1;
    bottom: 0px;
    width: 512px;
    right: 366px;
    padding: 80px;
    position: absolute;
    background-color: $pure-white;
    .title-text {
      font-size: 30px;
    }
  }
}
/* map-section - end */

/* 16 - contact pages - end
==================================================================================================== */
